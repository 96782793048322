import React, { Component } from "react";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import flyerpage from "../../images/flyerpage.png";
import flyerActiveIcon from "../../images/flyerActiveIcon.png";
import "./Flyers.css";
import FlyerDetail from "./FlyerDetail";
import { connect } from "react-redux";
import {
    flyerDetailGridView,
    updateFlyerDetail,
} from "../../store/actions/flyersAction";
import { getFlyersPageDataObject } from "../../store/actions/flyersDataAction";
import {
    getFlyerDetailsObject,
    updateFlyerDetailsCurrentPage,
} from "../../store/actions/flyerDetailAction";
import Loader from "../CommonFiles/Loader";
import moment from "moment";
import ReactPaginatePagination from "../OfferData/pagination";
import ApiCalls from "../ApiList/apiCalls";
import Tooltip from "@mui/material/Tooltip";
import UpdateJson from "../CommonFiles/UpdateJson";
import TopFilter from "../TopFilter/TopFilter";
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));

const ApiList = new ApiCalls();
class FlyerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 20,
            pageNo: 1,
            gridView: false,
            loading: false,
        };
    }

    componentDidMount() {
        this.props.getFlyersPageDataObject(true);
        this.props.updateFlyerDetail(false);
        document.removeEventListener("pageSizeChange", this.onPageSizeChange);
        document.addEventListener(
            "pageSizeChange",
            this.onPageSizeChange.bind(this)
        );
        document.removeEventListener("newsearch", this.newSearch);
        document.addEventListener("newsearch", this.newSearch.bind(this));
        if (prmissionData?.isFlyerAudit)
            ApiList.getBrands().then((response) => {
                const newToken = response.headers.token;
                const userData = JSON.parse(localStorage.getItem("userData"));
                const newUserData = { ...userData, token: newToken };
                localStorage.setItem("userData", JSON.stringify(newUserData));
                const customEvent1 = new CustomEvent("brandsList", {
                    detail: {
                        brandList: response.data.brandList,
                    },
                });
                document.dispatchEvent(customEvent1);
            });
    }
    newSearch(ev) {
        this.props.updateFlyerDetail(false);
    }
    onPageSizeChange(ev) {
        this.setState({ pageSize: ev.detail.pageSize });
        this.setState({ pageNo: ev.detail.pageNo });
    }
    // componentDidUpdate(prevProps) {
    //   if (
    //     this.props.flyerDetail != prevProps.flyerDetail &&
    //     this.props.flyerDetail == false
    //   ) {
    //     const userData = JSON.parse(localStorage.getItem("userData"));
    //   }
    // }
    activeOffers = (flyerEndDate) =>
        new Date(flyerEndDate.split("/").reverse().join("/")) >= new Date();

    handlePageClick = ({ nextSelectedPage }) => {
        if (nextSelectedPage !== undefined) {
            UpdateJson([{ key: "pageNo", value: nextSelectedPage + 1 }]);
            this.props.getFlyersPageDataObject(true);
        }
    };

    render() {
        const {
            flyerDetail,
            updateFlyerDetail,
            flyerDetailGridView,
            getFlyerDetailsObject,
            flyersData,
            flyersLoading,
        } = this.props;
        return (
            <section>
                <TopFilter flyersPage={true} />
                <>
                    {!flyerDetail ? (
                        <>
                            <Loader open={flyersLoading} />
                            <section
                                className="outer-section"
                                style={{
                                    marginLeft:
                                        flyersData.flyersList && flyersData.flyersList.length
                                            ? "0%"
                                            : "10%",
                                    width:
                                        flyersData.flyersList && flyersData.flyersList.length
                                            ? ""
                                            : "1067px",
                                    borderRadius:
                                        flyersData.flyersList && flyersData.flyersList.length
                                            ? ""
                                            : "10px",
                                    padding:
                                        flyersData.flyersList && flyersData.flyersList.length
                                            ? "5px 20px 20px 20px"
                                            : "0px",
                                }}
                            >
                                {flyersData.flyersList && flyersData.flyersList.length ? (
                                    <>
                                        <sub className="total-records-flyer-para">
                                            Total Records:&nbsp;
                                            {flyersData.totalCount}
                                        </sub>
                                        <Grid
                                            container
                                            spacing={4}
                                            align="center"
                                            className="main-container"
                                        >
                                            {flyersData.flyersList.map((flyer) => (
                                                <Grid
                                                    key={flyer.ID}
                                                    item
                                                    xs={3}
                                                    className="card-grid"
                                                    style={{ marginTop: "15px" }}
                                                >
                                                    <Card
                                                        sx={{
                                                            minHeight: 341,
                                                            border: "1px solid #AAAAAA",
                                                        }}
                                                    >
                                                        <CardMedia
                                                            onClick={() => {
                                                                updateFlyerDetail(true);
                                                                this.setState({ gridView: true });
                                                                //updateFlyerDetailsCurrentPage(0);
                                                                flyerDetailGridView(false);
                                                                getFlyerDetailsObject(flyer.ID);
                                                            }}
                                                            sx={{
                                                                objectFit: "contain",
                                                                cursor: "pointer",
                                                                width: "182px",
                                                                marginTop: "20px",
                                                            }}
                                                            component="img"
                                                            height="247"
                                                            image={flyer.Image}
                                                            alt={flyerpage}
                                                        />
                                                        <div className="flyer-name-div">
                                                            <Tooltip title={flyer.Name_en} placement="top">
                                                                <span className="flyer-name">
                                                                    {flyer.Name_en.length > 35
                                                                        ? `${flyer.Name_en.substring(0, 35)}...`
                                                                        : `${flyer.Name_en}`}
                                                                </span>
                                                            </Tooltip>
                                                        </div>

                                                        <div className="card-content-div">
                                                            <div className="small-div-for-image">
                                                                <img
                                                                    src={flyer.Logo}
                                                                    style={{
                                                                        objectFit: "contain",
                                                                    }}
                                                                    height="100%"
                                                                    width="100%"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="valid-from-to-text">
                                                                <span> Valid from</span>
                                                                <br />
                                                                <span>
                                                                    {moment(
                                                                        new Date(
                                                                            flyer.StartDate.split("/")
                                                                                .reverse()
                                                                                .join("/")
                                                                        )
                                                                    ).format("DD/MM")}
                                                                    &nbsp;to&nbsp;
                                                                    {moment(
                                                                        new Date(
                                                                            flyer.EndDate.split("/")
                                                                                .reverse()
                                                                                .join("/")
                                                                        )
                                                                    ).format("DD/MM")}
                                                                </span>
                                                            </div>

                                                            <div style={{display:prmissionData?.isShowStores ? "block" : "none" }}>
                                                                <div className="valid-from-to-text">
                                                                    <span> Valid in stores</span>
                                                                    <br />
                                                                    <span>
                                                                        {flyer.ValidStore.length > 0 ? (<span>{flyer.ValidStore}/{flyer.TotalStore}</span>) : (<span>NA</span>)
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="button-container">
                                                                <span
                                                                    className={
                                                                        flyer.flyerStatus
                                                                            ? "active-offers"
                                                                            : "ended-offers"
                                                                    }
                                                                >
                                                                    <img
                                                                        src={flyerActiveIcon}
                                                                        className="active-icon"
                                                                        alt=""
                                                                    />
                                                                    {flyer.flyerStatus ? "Active" : "Expired"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Grid container sx={{ height: "48px" }}>
                                            <Grid
                                                item
                                                xs={6}
                                                sx={{
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <ReactPaginatePagination
                                                    handlePageClick={this.handlePageClick}
                                                    totalRecords={flyersData.totalCount}
                                                    pagesize={this.state.pageSize}
                                                    initialPage={this.state.pageNo - 1}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : flyersLoading ? (
                                    <Loader open={flyersLoading} />
                                ) : (
                                    <div className="no-data-found-div">
                                        <p className="no-data-found-p">No Data Found</p>
                                    </div>
                                )}
                            </section>
                        </>
                    ) : (
                        <FlyerDetail
                            handelState={() => {
                                this.setState({ gridView: !this.state.gridView })
                            }}
                        />
                    )}
                </>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        formData: state.filterForm,
        flyersPage: state.flyersPage.flyersPageRender,
        flyerDetail: state.flyersPage.flyerDetailPage,
        flyersFormData: state.flyersPage,
        flyersData: state.flyersData.flyersPageData,
        flyersLoading: state.flyersData.loading,
        flyersState: state.flyersPage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateFlyerDetail: (newValue) => dispatch(updateFlyerDetail(newValue)),
        flyerDetailGridView: (newValue) => dispatch(flyerDetailGridView(newValue)),
        getFlyersPageDataObject: (flag) => dispatch(getFlyersPageDataObject(flag)),
        getFlyerDetailsObject: (flyerId) =>
            dispatch(getFlyerDetailsObject(flyerId)),
        updateFlyerDetailsCurrentPage: (currentPageNumber) =>
            dispatch(updateFlyerDetailsCurrentPage(currentPageNumber)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FlyerPage);
