import React, { Component } from "react";
import { connect } from "react-redux";
import AdditionalFilters from "./AdditionalFilter/AdditionalFilter";
import Table from "./OfferData/ListView.js";
import OfferDataGridView from "./OfferData/offerDataGridView";
import Loader from "./CommonFiles/Loader";
import { Grid } from "@mui/material";
import ReactPaginatePagination from "./OfferData/pagination";
import {
  getOfferByUserPermission,
  toggleInitialize,
} from "../store/actions/offerImageActions";
import { clearSearch } from "../store/actions/filterFormDataAction";
import CompareBottomLine from "./Compare/CompareBottomLine";
import NoDataFound from "./NoDataFound";
import ApiCalls from "./ApiList/apiCalls";
import RefineFilter from "./RefineFilter/RefineFilter";
import { getDataByPermission } from "../store/actions/getDataByPermissionActions";
import { Navigate } from "react-router-dom";
import TopFilter from "./TopFilter/TopFilter";
import WelcomeScreen from "./WelcomeScreen/WelcomeScreen";
import backToTopIcon from "../images/backToTopIcon.svg";
import UpdateJson from "./CommonFiles/UpdateJson";
import { event } from "jquery";

const ApiList = new ApiCalls();
const userData = JSON.parse(localStorage.getItem("userData"));
class Offers extends Component {
  static displayName = Offers.name;
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 20,
      pageNo: 1,
    };
  }

  state = { notification: "", value: "", scrollToTopButtonVisible: false };

  toggleVisible = () => {
    if (window.pageYOffset > 500) {
      this.setState({ scrollToTopButtonVisible: true });
    } else {
      this.setState({ scrollToTopButtonVisible: false });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleVisible);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.toggleVisible);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const userFilterData = ApiList.getInitialState();
    this.setState({ pageNo: userFilterData.pageNo });
    this.setState({ pageSize: userFilterData.pageSize });
    localStorage.setItem("userFilterData", JSON.stringify(userFilterData));
    this.props.toggleInitialize(false);
    this.props.clearSearch();
    document.addEventListener("clear", this.onStateChange.bind(this));
    document.removeEventListener("pageSizeChange", this.onPageSizeChange);
    document.addEventListener(
      "pageSizeChange",
      this.onPageSizeChange.bind(this)
    );
  }
  onPageSizeChange(ev) {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    this.setState({ pageSize: ev.detail.pageSize });
    this.setState({ pageNo: userFilterData.pageNo });
  }
  onStateChange() {
    //this.props.offerData.offerLibraryDisplays = [];
  }

  handlePageClick = ({ nextSelectedPage }) => {
    if (nextSelectedPage != undefined) {
      const { getOfferByUserPermission } = this.props;
      UpdateJson([
        {
          key: "pageNo",
          value: nextSelectedPage + 1,
        },
      ]);
      if (nextSelectedPage + 1 <= 100000) {
        const customEvent = new CustomEvent("onPageChange", {
          detail: {
            pageNo: nextSelectedPage + 1,
          },
        });
        document.dispatchEvent(customEvent);
        getOfferByUserPermission();
      } else {
        alert(
          "Please select more filter to view all offers, page size should be less then 100000."
        );
      }
    }
  };
  changeValue = (val) => {
    this.setState({ value: val });
  };

  render() {
    const { offerData, loadingByOfferPermission, initialize } = this.props;
    const { notification } = this.state;
    if (!userData) {
      return <Navigate replace to="/" />;
    }
    if (notification) {
      return (
        <section>
          {notification.Message_En.indexOf("</") !== -1 ? (
            <div
              style={{
                padding: "22px 29px",
                height: "80vh",
                overflowY: "auto",
                backgroundColor: "#ECEFF4",
              }}
              dangerouslySetInnerHTML={{
                __html: notification.Message_En.replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></div>
          ) : (
            notification.Message_En
          )}
        </section>
      );
    }

    return (
      <div>
        <Loader open={loadingByOfferPermission} />
        <TopFilter />
        {initialize ? (
          <>
            <div className="refine-filter-and-additional-filter-container">
              <RefineFilter />

              <>
                <section
                  className="addtional-filter-and-offer-grid-container"
                  style={{
                    width: this.props.flyersPage ? "auto" : "83.5%",
                    margin: this.props.flyersPage
                      ? "8px auto"
                      : "0px 0px 0px 8px",
                  }}
                >
                  <AdditionalFilters />

                  <div
                    style={{
                      // visibility: offerData?.offerLibraryDisplays?.length
                      //   ? "visible"
                      //   : "hidden",
                      display: offerData?.offerLibraryDisplays?.length
                        ? "block"
                        : "none",
                    }}
                  >
                    <>
                      <div
                        style={{
                          display: !this.props.view ? "block" : "none",
                        }}
                      >
                        <OfferDataGridView
                          offerData={this.props.offerData?.offerLibraryDisplays}
                          savedOffersList={false}
                        />
                      </div>
                      <div
                        style={{
                          display: !this.props.view ? "none" : "block",
                        }}
                      >
                        <Table />
                      </div>
                      <section className="pagination-container">
                        <Grid container>
                          <Grid
                            item
                            xs={5}
                            sx={{ marginTop: "6px", marginLeft: "14px" }}
                          >
                            <ReactPaginatePagination
                              handlePageClick={this.handlePageClick}
                              totalRecords={
                                this.props.offerData &&
                                this.props.offerData.offerLibraryDisplays[0] &&
                                this.props.offerData.offerLibraryDisplays[0]
                                  .totalRecords
                              }
                              pagesize={this.state.pageSize}
                              initialPage={this.state.pageNo - 1}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={6.4}
                            style={{
                              maxHeight: "50px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CompareBottomLine />
                          </Grid>
                        </Grid>
                      </section>
                    </>
                  </div>
                  <div
                    style={{
                      // visibility: offerData?.offerLibraryDisplays?.length
                      //   ? "hidden"
                      //   : "visible",
                      display: offerData?.offerLibraryDisplays?.length
                        ? "none"
                        : "block",
                    }}
                  >
                    <NoDataFound
                      loading={this.props.loadingByOfferPermission}
                    />
                  </div>
                </section>
              </>
            </div>
          </>
        ) : (
          <WelcomeScreen />
        )}
        {(this.state.scrollToTopButtonVisible && !this.props.flyersPage) ||
        (this.state.scrollToTopButtonVisible &&
          this.props.flyersPage &&
          this.props.gridView) ? (
          <button
            id="back-to-top-button"
            style={{ left: this.props.flyersPage ? "26px" : "76px" }}
            onClick={() => {
              var b = window.scrollHeight - window.clientHeight;
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <img className="back-to-top-icon" alt="" src={backToTopIcon} />
            Back to Top
          </button>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialize: state.userPermissionOfferData.initialize,
    offerData: state.userPermissionOfferData.imageData,
    loading: state.userPermissionOfferData.loading,
    view: state.userPermissionOfferData.view,
    loadingByOfferPermission: state.userPermissionOfferData.loading,
    flyersPage: state.flyersPage.flyersPageRender,
    flyerDetail: state.flyersPage.flyerDetailPage,
    flyersData: state.flyersData.flyersPageData,
    calendarView: state.filterForm.calendarView,
    gridView: state.flyersPage.flyerDetailGridView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOfferByUserPermission: () =>
      dispatch(getOfferByUserPermission("page", true)),
    getDataByPermission: (userId, isTnC, x) =>
      dispatch(getDataByPermission(userId, isTnC, x)),
    toggleInitialize: (value) => dispatch(toggleInitialize(value, true)),
    clearSearch: (newValue) => dispatch(clearSearch(newValue)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Offers);
